import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, OverlayTrigger, Popover, Spinner, Table, ToggleButton } from 'react-bootstrap';
import {
  INVOICE_GENERATION_FILER_PENDING,
  INVOICE_PAGINATION_DEFAULT_SIZE,
  InvoiceDto,
} from '../../models/invoice.dto';
import InvoicesListItem from './invoices-list-item/invoices-list-item';
import ModalContainer from '../layout/modal-container';
import ExportModal from './export-modal';
import {
  FilterInvoiceBody, useExportCsvMutation,
  useExportInvoicesMutation,
  useFilterInvoicesQuery,
} from '../../store/invoices/invoices.api';
import {groupBy} from '../../utils/groupBy';
import './invoices-list.scss';
import {AccountingNumberDto} from '../../models/accounting-number.dto';
import {CostCenterDto} from '../../models/cost-center.dto';
import {useTranslation} from 'react-i18next';
import SearchBar from './search-bar/search-bar';
import {formatDate} from '../../utils/dateFormatUtil';
import {useGetUserDataQuery} from '../../store/user/user.api';
import {CaretDownFill, CaretUpFill, GearFill} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import {getColumnsConfig} from './columns/columns';
import {useGetSettingsQuery, usePatchSettingsMutation} from '../../store/settings/settings.api';
import moment from 'moment';
import ActionDropdown from './action-dropdown/action-dropdown';
import { isEmptyArray, notBlank, notEmpty, notEmptyArray } from '../../utils/base';
import { IS_ADMIN } from '../../utils/role';
import ExportWarningModal from './export-warning-modal';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

interface PropsType {
  accountingNumbers: AccountingNumberDto[];
  costCenters: CostCenterDto[];
  accountingNumbersInUse: AccountingNumberDto[];
  costCentersInUse: CostCenterDto[];
  handleInvoiceNumberOrCenterUpdate: () => void;
}

const defaultFilterParams: FilterInvoiceBody = {
  name: '',
  fromDate: '2022-01-01',
  toDate: moment().add(1, 'day').format('YYYY-MM-DD'),
  fromAmount: '',
  toAmount: '',
  costCenter: '',
  accountingNumber: '',
  generationDateFilter: INVOICE_GENERATION_FILER_PENDING,
  page: 0,
  size: INVOICE_PAGINATION_DEFAULT_SIZE
};

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}

const InvoicesList = ({
  accountingNumbersInUse, costCentersInUse, costCenters
}: PropsType) => {
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceDto[]>([]);
  const [shownInvoices, setShownInvoices] = useState<InvoiceDto[]>([]);
  const [paymentDate, setPaymentDate] = useState<Date>(new Date());
  const [filterParams, setFilterParams] = useState<FilterInvoiceBody>(defaultFilterParams);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showExportWarningModal, setShowExportWarningModal] = useState<number[]>([]);
  const [showEmptyBankAccountModal, setShowEmptyBankAccountModal] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [translate] = useTranslation(['common', 'invoice', 'account']);

  // pagination states
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isLoadingRef = useRef(false);

  const [patchSettings] = usePatchSettingsMutation();
  const {data: settings} = useGetSettingsQuery();

  const [sortBy, setSortBy] = useState(settings?.sortBy?.column || 'invoiceId');
  const [ascending, setAscending] = useState<boolean>(settings?.sortBy ?  settings.sortBy?.order === 'ASC' : true);
  const [exportInvoices] = useExportInvoicesMutation();
  const [exportCsv] = useExportCsvMutation();

  const containerStyle = IS_ADMIN() ? 'justify-content-between' : 'justify-content-end';

  const {data: filterInvoices} = useFilterInvoicesQuery({
    name: filterParams.name,
    fromDate: filterParams.fromDate,
    toDate: filterParams.toDate,
    fromAmount: filterParams.fromAmount,
    toAmount: filterParams.toAmount,
    costCenter: filterParams.costCenter,
    accountingNumber: filterParams.accountingNumber,
    generationDateFilter: filterParams.generationDateFilter,
    sortBy: sortBy,
    ascending: ascending,
    page: page,
    size: INVOICE_PAGINATION_DEFAULT_SIZE
  });
  const {data: user} = useGetUserDataQuery();

  const filterInvoicesContent = filterInvoices?.content;

  const handleInvoiceSelection = (invoice: InvoiceDto, checked: boolean) => {
    checked
      ? setSelectedInvoices([...selectedInvoices, invoice])
      : setSelectedInvoices(
        selectedInvoices.filter((item) => item.invoiceId !== invoice.invoiceId),
      );
  };

  useEffect(() => {
    setSelectedInvoices(
      selectedInvoices.map(
        (selectedInvoice) =>
           filterInvoicesContent?.find(
            (invoice) => invoice?.invoiceId === selectedInvoice?.invoiceId,
          ) as InvoiceDto,
      ),
    );
  }, [filterInvoices]);

  useEffect(() => {
    if (filterInvoicesContent) {
      setShownInvoices(filterInvoicesContent);
    }
  }, [filterInvoices]);

  const handleExportToPaymentClick = () => {
    const alreadyPaidInvoices = selectedInvoices.filter(invoice => notBlank(invoice.dtaDate)).map(i => i.invoiceId);

    selectedInvoices.filter((invoice) => !!invoice).some((invoice) => !invoice?.bankAccount)
      ? setShowEmptyBankAccountModal(true)
       : isEmptyArray(alreadyPaidInvoices)
          ? setShowExportModal(true)
          : setShowExportWarningModal(alreadyPaidInvoices);
  };

  const getExportFileFormat = () =>
    Object.entries(groupBy(selectedInvoices, (i) => i.bankAccount.name)).length > 1
      ? 'zip'
      : 'xml';

  const resetSelectedInvoices = () => setSelectedInvoices([]);

  const handleSearchChange = (
    name,
    fromDate,
    toDate,
    fromAmount,
    toAmount,
    costCenter,
    accountingNumber,
    generationDateFilter,
    page,
    size
  ) => {
    setFilterParams({
      name,
      fromDate: formatDate(fromDate),
      toDate: formatDate(toDate),
      fromAmount,
      toAmount,
      costCenter,
      accountingNumber,
      generationDateFilter,
      page,
      size
    });
    resetSelectedInvoices();
    setPage(0);
    setHasMore(true);
  };

  const onSelectAllClicked = () => {
    if (!allSelected) {
      setAllSelected(true);
      setSelectedInvoices(shownInvoices);
    } else {
      setAllSelected(false);
      resetSelectedInvoices();
    }
  };

  const toggleAll = <ToggleButton
    style={{padding: 6}}
    type='checkbox'
    variant='outline-primary'
    checked={allSelected}
    value='allSelected'
    onClick={onSelectAllClicked}
    id={'toggle-all-btn'}
  />;

  const handleExportToCsvClick = () => {
    exportCsv(filterParams);
  }

  const getSortedBy = (field: string) => {
    if (field === sortBy) {
      if (ascending) {
        return <CaretDownFill color='white'/>;
      } else {
        return <CaretUpFill color='white'/>;
      }
    }
  }

  const handleSortBy = (field: string) => {
    if (sortBy == field) {
      setAscending(a => !a);
    } else {
      setAscending(true);
      setSortBy(field);
    }
  }

  useDidMountEffect(() => {
    const sort = {
      column: sortBy,
      order: ascending ? 'ASC' : 'DESC' as 'ASC' | 'DESC'
    };
    patchSettings({sortBy: sort});
  }, [ascending, sortBy])


  const costCentersExist = notEmptyArray(costCenters);

  const [columns, setColumns] = useState(getColumnsConfig(settings?.hiddenColumns ?? [], costCentersExist));

  useDidMountEffect(() => {
    const hiddenColumns = columns
      .filter(c => !c.show)
      .map(c => c.sortBy)
    patchSettings({hiddenColumns});
  }, [columns])

  /** invite scroll use effects **/
  useEffect(() => {
    setIsLoading(true);
    isLoadingRef.current = true;

    if (filterInvoicesContent) {
      if (page === 0) {
        setShownInvoices(filterInvoicesContent);
      } else {
        setShownInvoices([...shownInvoices, ...filterInvoicesContent]);
      }

      setHasMore(filterInvoicesContent.length > 0 ? filterInvoices?.totalPages > page + 1 : false);
    }

    setIsLoading(false);
    isLoadingRef.current = false;

  }, [filterInvoices]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight - 1;

      if (scrollPosition >= bottomPosition && hasMore && !isLoadingRef.current) {
        setIsLoading(true);
        isLoadingRef.current = true;
        setPage(prevPage => prevPage + 1);
      }
    };

    if (isLoading) return;
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);
  /** invite scroll use effects **/

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {columns.map(c => (
          <Form.Check type='switch' label={translate(c.label)} id={c.label} key={c.label} checked={c.show} onClick={() => {
            setColumns(columns => {
              const i = columns.findIndex(i => c.label === i.label);
              columns[i].show = !columns[i].show;
              return [...columns];
            });
          }
          }/>
        ))}
      </Popover.Body>
    </Popover>
  );

  const dataForExcelExport = () => {
    return selectedInvoices.map(i => {
      const data = {
        name: i.invoiceId,
        company: i.company,
        status: notEmpty(i.approvals) ? 'Approved' : notEmpty(i.rejections) ? 'Rejected' : 'Pending',
        amount: i.amount,
        currency: i.currency,
        bankAccount: i.bankAccount?.name,
        vatCode: i.vat ? i.vat.code : '',
        accountingNumber: i.accountingNumber,
        accountingRemark: i.accountingRemark,
        description: i.description,
        generationDate: i.dtaDate ? moment(i.dtaDate).format('DD-MM-YYYY') : '',
        paymentDate: i.paymentDate ? moment(i.paymentDate).format('DD-MM-YYYY') : '',
        costCenter: i.costCenter
      }

      if (costCentersExist) {
        data.costCenter = i.costCenter;
      }

      return data
    });
  }

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataForExcelExport());
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoices');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'invoices.xlsx');
  };

  return (
     <>
     <div className={`d-flex ${containerStyle}`}>
       {IS_ADMIN() &&
          <>
          <div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {translate('invoice:invoice.export')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                   disabled={selectedInvoices?.length === 0}
                   onClick={handleExportToPaymentClick}>
                  {translate('invoice:invoice.exportForPayment')}
                </Dropdown.Item>
                <Dropdown.Item onClick={handleExportToCsvClick}>
                  {translate('invoice:invoice.exportForCsv')}
                </Dropdown.Item>
                <Dropdown.Item
                   disabled={selectedInvoices?.length === 0}
                   onClick={handleExportToExcel}
                >
                  {translate('invoice:invoice.exportToExcel')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="actions-wrapper">
             <ActionDropdown
               selectedInvoices={selectedInvoices}
               resetSelectedInvoices={resetSelectedInvoices}
              />
          </div>
          </>
       }

      <div className='ml-auto mb-1 mx-3 top' style={{display: 'inline-block'}}>
          <SearchBar
            onSearch={handleSearchChange}
            searchParams={defaultFilterParams}
            costCenters={costCentersInUse}
            accountingNumbers={accountingNumbersInUse}
          />
        </div>
      </div>

      {shownInvoices?.length > 0 ? (
        <Table variant='dark' striped table-borderless='true' hover>
          <thead>
          <tr>
            <th className='selection'>{toggleAll}</th>
            {columns.map(c => {
                if (c.show) {
                  return (<th className={c.className} onClick={() => handleSortBy(c.sortBy)} key={c.label}>
                    {translate(c.label)} {getSortedBy(c.sortBy)}
                  </th>);
                }
              }
            )}
            <th className={'action'}>
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                <GearFill/>
              </OverlayTrigger>
            </th>
          </tr>
          </thead>
          <tbody>
          {shownInvoices.map((invoice) => (
            <InvoicesListItem
              columns={columns}
              invoice={invoice}
              selected={selectedInvoices}
              handleInvoiceSelection={handleInvoiceSelection}
              key={invoice.invoiceId}
              user={user}
            />
          ))}
          </tbody>
        </Table>
      ) : (
        <div>{translate('invoice:invoice.noInvoices')}</div>
      )}
      {isLoading && <div className='spinner-wrapper'> <Spinner animation="border" role="status" /> </div> }
      <ModalContainer
        modalContent={
          <ExportModal
            selectedInvoices={selectedInvoices}
            paymentDate={paymentDate}
            setPaymentDate={setPaymentDate}
          />
        }
        modalHeader={translate('invoice:invoice.exportPayment')}
        submitButtonText={translate('common:common.export')}
        show={showExportModal}
        onCancel={() => setShowExportModal(false)}
        onSubmit={() => {
          resetSelectedInvoices();
          exportInvoices({
            invoiceIds: selectedInvoices.map((invoice) => invoice.invoiceId),
            paymentDate: paymentDate.toISOString(),
            fileType: getExportFileFormat(),
          });
          setShowExportModal(false);
        }}
      />

       <ModalContainer
          modalContent={
            <ExportWarningModal
               invoiceIds={showExportWarningModal}
            />
          }
          modalHeader={translate('invoice:invoice.invoicesAlreadyPaid')}
          submitButtonText={translate('common:common.proceed')}
          show={notEmptyArray(showExportWarningModal)}
          onCancel={() => setShowExportWarningModal([])}
          onSubmit={() => {
            setShowExportWarningModal([])
            setShowExportModal(true);
          }}
       />

      <ModalContainer
        modalContent={
          <div>{translate('account:bankAccount.selectedBankAccountWarning')}</div>
        }
        modalHeader={translate('account:bankAccount.noBankAccountSelected')}
        submitButtonText={translate('common:common.close')}
        show={showEmptyBankAccountModal}
        onSubmit={() => setShowEmptyBankAccountModal(false)}
      />
    </>
  );
};

export default InvoicesList;
