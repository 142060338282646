import {InvoiceDto} from '../../../models/invoice.dto';
import {useUpdateInvoiceMutation} from '../../../store/invoices/invoices.api';
import { useGetVatCodesQuery } from '../../../store/vat-codes/vat-codes.api';
import { VatCodeDto } from '../../../models/vat-code.dto';
import DropdownWithFilter from '../../elements/dropdown-with-filter/dropdown-with-filter';
import React from 'react';

const VatCodeCell = ({invoice}: {invoice: InvoiceDto}) =>  {
  const [updateInvoice] = useUpdateInvoiceMutation();
  const { data: vatCodes } = useGetVatCodesQuery();  
  

  const handleVatCodeChange = ( vatId: number, invoice: InvoiceDto) => {
    const selectedVat = vatCodes?.find((vat) => vat.id === vatId);
    
    if (selectedVat) {
      updateInvoice({
        invoiceId: invoice.invoiceId,
        data: { ...invoice, vat: selectedVat }
      });
    }  };

 const handleVatCodeSelection = (vatCode: VatCodeDto) => {
    handleVatCodeChange(vatCode.id, invoice);
 };

  return <td className={'filter-dropdown vat-code'}>
    <DropdownWithFilter

      className={ `toggle ${invoice.vat ? '' : 'empty-value'}`}
      items={vatCodes || []}
      selectedValue={invoice.vat?.code}
      getMenuItemValue={(vat: VatCodeDto) => vat?.code}
      handleChange={handleVatCodeSelection}
      handleFilter={(vatCodes, filterValue) =>
        vatCodes.filter(
           (vatCode: VatCodeDto) =>
              vatCode.code.toLowerCase().includes(filterValue.toLowerCase())
        )
     }
    ></DropdownWithFilter>
  </td>;
}

export default VatCodeCell;